export const createTables = (db: any) => {
  db.run(`CREATE TABLE students (name TEXT,     sponsor TEXT,      stream TEXT,   season TEXT, year INTEGER)`)
  db.run(`INSERT INTO students  (name,          sponsor,           stream,        season,      year) VALUES
                                ('Lydia',       'Experian',        'Software',    'Summer',    2022),
                                ('Emma',        'IPO',             'Software',    'Summer',    2022),
                                ('Alice',       'IPO',             'Software',    'Summer',    2022),
                                ('Reema',       'Moneybox',        'Software',    'Summer',    2022),
                                ('Leslie',      'Dunelm',          'Full Stack',  'Spring',    2022),
                                ('Ellie',       'Dunelm',          'Full Stack',  'Spring',    2022),
                                ('Dominika',    'Dunelm',          'Full Stack',  'Spring',    2022),
                                ('Agne',        'Dunelm',          'Full Stack',  'Spring',    2022),
                                ('Emma',        'Dunelm',          'Full Stack',  'Spring',    2022),
                                ('Georgie',     'Swiss Re',        'Full Stack',  'Spring',    2022),
                                ('Ciara',       'CFG',             'Software',    'Spring',    2022),
                                ('Josephine',   'CFG',             'Software',    'Spring',    2022),
                                ('Zhaonan',     'Ford',            'Full Stack',  'Spring',    2022),
                                ('Nathile',     'Ford',            'Full Stack',  'Spring',    2022),
                                ('Ali',         'Slalom',          'Full Stack',  'Spring',    2022),
                                ('Chloe',       'Slalom',          'Full Stack',  'Spring',    2022),
                                ('Ellie',       'Slalom',          'Full Stack',  'Spring',    2022),
                                ('Lana',        'Slalom',          'Full Stack',  'Spring',    2022),
                                ('Magda',       'Brit Insurance',  'Full Stack',  'Spring',    2022),
                                ('Manal',        NULL,             'Full Stack',  'Spring',    2022),
                                ('Ella',         NULL,             'Full Stack',  'Spring',    2022),
                                ('Diane',       'CFG',             'Data',        'Spring',    2022),
                                ('Joey',         NULL,             'Data',        'Spring',    2022),
                                ('Rachel',      'NatWest',         'Data',        'Autumn',    2021),
                                ('Ayesha',      'Deloitte',        'Software',    'Autumn',    2021),
                                ('Chiara',      'Deloitte',        'Software',    'Autumn',    2021),
                                ('Dare',        'Bank of America', 'Data',        'Summer',    2021),
                                ('Mhai',        'Bank of America', 'Data',        'Summer',    2021),
                                ('Chloe',       'Bank of America', 'Data',        'Summer',    2021),
                                ('Shayli',      'CFG',             'Software',    'Summer',    2021)`)
  db.run(`CREATE TABLE products (name TEXT,               department TEXT,          stock INTEGER, price FLOAT, on_offer BOOLEAN)`)
  db.run(`INSERT INTO products  (name,                    department,               stock,         price,       on_offer) VALUES
                                ('Grated cheddar',        'Dairy and alternatives', 50,            1.50,        FALSE),
                                ('Button mushrooms',      'Fruit and veg',          20,            0.99,        FALSE),
                                ('Bag of mixed peppers',  'Fruit and veg',          20,            1.35,        FALSE),
                                ('Long-grain white rice', 'Pasta and rice',         40,            0.50,        FALSE),
                                ('Banana smoothie',       'Drinks',                 0,             1.70,        TRUE),
                                ('Paprika pringles',      'Snacks',                 NULL,          1.75,        TRUE),
                                ('Rich tea biscuits',     'Snacks',                 40,            0.25,        FALSE),
                                ('Flat peaches',          'Fruit and veg',          0,             0.30,        FALSE),
                                ('Macaroni cheese',       'Ready meals',            10,            2.00,        TRUE),
                                ('Smooth orange juice',   'Drinks',                 20,            1.50,        FALSE),
                                ('Rigatoni',              'Pasta and rice',         50,            0.70,        FALSE),
                                ('Oat cream',             'Dairy and alternatives', NULL,          1.50,        FALSE),
                                ('Haribo giant strawbs',  'Snacks',                 20,            1.00,        TRUE),
                                ('Easy peeler oranges',   'Fruit and veg',          40,            1.00,        FALSE),
                                ('Broccoli',              'Fruit and veg',          35,            0.50,        FALSE)`)
  db.run(`CREATE TABLE people (name TEXT,     postcode TEXT, license_number TEXT)`)
  db.run(`INSERT INTO people  (name,          postcode,      license_number) VALUES
                              ('Emma M',      'AB1 2CD',     'MAEM12345'),
                              ('Emily F',     'EF3 4GH',     NULL),
                              ('Kim G',       'IJ5 6KL',     NULL),
                              ('Sophie B',    'MN7 8OP',     'BLSO67890'),
                              ('Elizabeth W', 'QR9 0ST',     'WIEL13579')`)
  db.run(`CREATE TABLE driving_licenses (number TEXT, issue_date TEXT, points_incurred INTEGER)`)
  db.run(`INSERT INTO driving_licenses  (number,      issue_date,      points_incurred) VALUES
                                        ('WIEL13579', '30/05/1965', 9),
                                        ('FLEL86420', '08/02/1981', 0),
                                        ('BLSO67890', '16/10/2010', 0),
                                        ('PAAN86420', '06/01/2014', 0),
                                        ('MAEM12345', '15/09/2021', 6)`)
  db.run(`CREATE TABLE marks (name TEXT, subject TEXT, mark INTEGER)`)
  db.run(`INSERT INTO marks  (name,      subject,      mark) VALUES
                             ('Ali',     'Python',     100),
                             ('Lana',    'Python',     101),
                             ('Ella',    'Python',     102),
                             ('Manal',   'Python',     103),
                             ('Ciara',   'SQL',        104),
                             ('Ciara',   'Python',     105),
                             ('Anood',   'SQL',        106),
                             ('Anood',   'Python',     107),
                             ('Lydia',   'SQL',        108),
                             ('Lydia',   'Python',     109),
                             ('Reema',   'SQL',        110),
                             ('Reema',   'Python',     111),
                             ('Chloe',   'SQL',        112),
                             ('Mhai',    'SQL',        113),
                             ('Joey',    'SQL',        114),
                             ('Diane',   'SQL',        115)`)
  db.run(`CREATE TABLE new_students (name TEXT, class TEXT)`)
  db.run(`INSERT INTO new_students  (name,      class) VALUES
                                    ('Ali',     'fullstack-1'),
                                    ('Lana',    'fullstack-1'),
                                    ('Ella',    'fullstack-1'),
                                    ('Manal',   'fullstack-1'),
                                    ('Ciara',   'software-2'),
                                    ('Anood',   'software-2'),
                                    ('Lydia',   'software-2'),
                                    ('Reema',   'software-2'),
                                    ('Chloe',   'data-1'),
                                    ('Mhai',    'data-1'),
                                    ('Joey',    'data-1'),
                                    ('Diane',   'data-1')`)
  db.run(`CREATE TABLE classes (class TEXT,    primary_marker TEXT)`)
  db.run(`INSERT INTO classes  (class,         primary_marker) VALUES
                               ('fullstack-1', 'Anna'),
                               ('software-2',  'Christine'),
                               ('data-1',      'Fiona')`)
}
